import React from "react"

const HeaderNoHero = ({ title, subtitle }) => {
  return (
    <header className="uk-position-relative uk-light">
      <div className="uk-light" style={{ backgroundColor: "rgba(0,0,0,0.8)" }}>
        <div className={`uk-light hero-copy uk-container uk-flex-auto uk-position-relative`}>
          <div
            className="uk-width-3-5@s"
            data-uk-scrollspy="cls: uk-animation-slide-right-medium; target: > *; delay: 150"
          >
            <h1 className="uk-margin-large-top">{title}</h1>
            {subtitle ? <h4 className="uk-margin-remove-top">{subtitle}</h4> : null}
          </div>
        </div>
      </div>
    </header>
  )
}

export default HeaderNoHero
